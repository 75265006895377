$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;
  @include display-linebreak;

  @include media-breakpoint-up(sm) {
    padding-right: 5rem;
  }
}
