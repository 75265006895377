$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  padding-top: $cms-heading-block-padding-top;
  margin-bottom: $cms-heading-block-margin-bottom;
  position: relative;

  .visible {
    position: absolute;
    z-index: 2;
    margin-top: 0.5rem;
  }

  .positioner {
    opacity: 0;
  }

  h1 {
    @include heading-block-h1;
  }

  h2 {
    @include heading-block-h2;
  }

  h3 {
    @include heading-block-h3;
  }

  h4 {
    @include heading-block-h4;
  }

  h5 {
    @include heading-block-h5;
  }

  h6 {
    @include heading-block-h6;
  }
}

.subTitle {
  display: inline-block;
  @include heading-block-subtitle;
}
